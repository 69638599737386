.content-body {
   width: 100%;
   height: calc(100% - 6.25rem) !important;
    .commissionEdit-contain {
        width: 100%;
        overflow-y: scroll;
        padding: 1.25rem 1.25rem;
        margin-left: -0.625rem;
        h1 {
            font-size: 1.875rem;
            font-weight: 500;
            height: 3.125rem;
        }
        .commissionEdit-form {
            padding: 3.125rem 1.875rem;
            // background: #fff;
            margin-bottom: 1.875rem;
            border-radius: 1rem;
            box-shadow: 0 .3125rem .3125rem 0 rgba(82, 63, 105, 0.05);
            height: auto;
            position: relative;
            .close-btn {
                position: absolute;
                top: 1.25rem;
                right: 1.25rem;
                height: auto;
            }
        }
        .header {
            padding: 1.25rem 1.25rem;
            background: #fff;
            margin-bottom: 1.875rem;
            border-radius: 1rem;
            box-shadow: 0 .3125rem .3125rem 0 rgba(82, 63, 105, 0.05);
            height: auto;
            h3 {
                height: 2.5rem;
                font-size: 1.25rem;
                font-weight: normal;
                color: #636466;
            }
            .row {
                display: flex;
                font-size: 1.125rem;
                font-weight: 600;
                justify-content: space-between;
                .dollar {
                    width: 22%;
                    background: #5bcfc5;
                    text-align: center;
                    padding: .9375rem 1.25rem;
                    border-radius: .625rem;
                    color: #fff;
                    font-size: .9375rem;
                }
                .euro {
                    width: 22%;
                    background: #5bcfc5;
                    text-align: center;
                    padding: .9375rem 1.25rem;
                    border-radius: .625rem;
                    color: #fff;
                    font-size: .9375rem;
                }
                .pound {
                    width: 22%;
                    background: #5bcfc5;
                    text-align: center;
                    padding: .9375rem 1.25rem;
                    border-radius: .625rem;
                    color: #fff;
                    font-size: .9375rem;
                }
                .auer {
                    width: 22%;
                    background: #5bcfc5;
                    text-align: center;
                    padding: .9375rem 1.25rem;
                    border-radius: .625rem;
                    color: #fff;
                    font-size: .9375rem;
                }
            }
        }
        .row-body {
            padding: 1.25rem 1.25rem;
            background: #fff;
            margin-bottom: 1.875rem;
            border-radius: 1rem;
            box-shadow: 0 .3125rem .3125rem 0 rgba(82, 63, 105, 0.05);
            height: auto;
            margin: 1.25rem 0;
            position: relative;
            .remove {
                position: absolute;
                top: .3125rem;
                right: .3125rem;
                height: auto;
                .close-icon{
                    width: 1.25rem;
                    height: 1.25rem;
                }
            }
            .row {
                display: flex;
                .col {
                    width: 33%;
                    p {
                        margin: 0 0 .9375rem;
                        font-size: 1.125rem;
                        font-weight: 500;
                        color: #969ba0;
                    }
                    input[type="text"] {
                        width: 90%;
                        border: .0625rem solid #dddddd;
                        padding: .625rem 1.25rem;
                        border-radius: .625rem;
                        &:focus {
                            outline: none;
                        }
                    }
                    .money-box {
                        width: 90%;
                        border: .0625rem solid #dddddd;
                        border-radius: .625rem;
                        overflow: hidden;
                        display: flex;
                        align-items: center;
                        input {
                            width: 85%;
                            padding: .625rem 1.25rem;
                            border: 0;
                            &:focus {
                                outline: none;
                            }
                        }
                        span {
                            display: block;
                            width: 15%;
                            height: 3.125rem;
                            border-left: .0625rem solid #dddddd;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #969ba0;
                            font-size: .875rem;
                        }
                    }
                }
            }
        }
        .prove {
            padding: 1.25rem 1.25rem;
            background: #fff;
            margin-bottom: 1.875rem;
            border-radius: 1rem;
            box-shadow: 0 .3125rem .3125rem 0 rgba(82, 63, 105, 0.05);
            height: auto;
            margin: 1.25rem 0;
            .row {
                padding: .625rem 0;
                display: flex;
                .title {
                    width: 20%;
                    font-size: 1.25rem;
                    color: #636466;
                }
                .file-box {
                    width: 70%;
                    .file {
                        width: 90%;
                        border: .0625rem solid #cecbcb;
                        // padding: 10px 10px;
                        height: 2.8125rem;
                        border-radius: .625rem;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        position: relative;
                        overflow: hidden;
                        p {
                            display: flex;
                            align-items: center;
                            padding: 0 1.25rem;
                            color: #969ba0;
                            font-size: 1.125rem;
                        }
                        .btn {
                            width: 6.25rem;
                            border-left: .0625rem solid #cecbcb;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            color: #969ba0;
                            font-size: 1.125rem;
                        }
                        .file-input {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            opacity: 0;
                        }
                    }
                    .img-row {
                        display: flex;
                        .img-box {
                            margin: 1.25rem 1.875rem .625rem 0;
                            width: 5.625rem;
                            height: 5.625rem;
                            border: .0625rem solid #cecbcb;
                            position: relative;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            .remove {
                                position: absolute;
                                top: -0.625rem;
                                right: -0.625rem;
                                height: auto;
                                .close-icon{
                                    width: 1.25rem;
                                    height: 1.25rem;
                                }
                            }
                            img {
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }
                    }
                }
            }
        }
        .commission-footer {
            padding: 1.25rem 1.25rem;
            background: #fff;
            margin-bottom: 1.875rem;
            border-radius: 1rem;
            box-shadow: 0 .3125rem .3125rem 0 rgba(82, 63, 105, 0.05);
            height: auto;
            .row {
                display: flex;
                .col {
                    width: 33%;
                    font-size: 1rem;
                    .submit {
                        width: 90%;
                        padding: .625rem;
                        background-color: #5bcfc5;
                        border-radius: .3125rem;
                        text-align: center;
                        color: #fff;
                        font-size: 1.125rem;
                        font-weight: 600;
                        &:hover {
                            background-color: #38bfb3;
                        }
                    }
                    P {
                        font-size: 1rem;
                        span {
                            color: red;
                            font-size: .9375rem;
                        }
                        margin: .3125rem 0;
                    }
                }
            }
        }
    }
}
