// html,*{
//     min-height: 100vh;
//   }

.tab-box {
    // position: absolute;
    // top: 0;
    // left: 0;
    width: 100%;
    padding: 0.9375rem;
    z-index: 11;
    overflow: hidden;
    .tab {
        width: 100%;
        height: 100%;
        border-radius: 1.5625rem;
        background: #fff;
        overflow: hidden;
        .nav-header {
            height: 6.25rem;
            width: 100%;
            padding: 0 1.25rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            overflow: hidden;
            a {
                display: flex;
                align-items: center;
                .logo {
                    margin: 0 1.25rem 0 0;
                    width: 2.5rem;
                    height: 2.5rem;
                }
                .hamburger {
                    width: 2.5rem;
                    height: 2.5rem;
                }
                span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: rgb(35, 42, 65);
                    font-weight: 600;
                    font-size: 1.5rem;
                    font-family: AliMaMa;
                }
            }
        }
        .tab-body {
            width: 100%;
            height: 100%;
            overflow: scroll;
            &::-webkit-scrollbar {
                width: 0.3125rem;
                // height: 10px; // 高度写不写，都不影响，因为会根据内容的长度自动计算
            }

            &::-webkit-scrollbar-thumb {
                background: #b9c4e9; // 滑块颜色
                border-radius: 0.125rem; // 滑块圆角
            }
            padding: 0 1.875rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            .profile {
                height: 4.375rem;
                margin: 0 0 1.25rem;
                border: 0.0625rem solid #f5f5f5;
                border-radius: 1rem;
                padding: 0.625rem 1.375rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .left {
                    display: flex;
                    align-items: center;
                    .img-box {
                        width: 3.125rem;
                        height: 3.125rem;
                        border-radius: 50%;
                        // border: 1px solid #5bcfc5;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        overflow: hidden;

                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }

                    .user-text {
                        margin-left: 1rem;
                        height: 3.125rem;
                        display: flex;
                        flex-direction: column;
                        align-items: start;
                        justify-content: center;
                        p {
                            height: 1rem;
                            font-size: 1rem;
                            b {
                                font-size: 1rem;
                            }
                        }
                        h6 {
                            font-size: 0.75rem;
                            margin: 0.3125rem 0 0;
                            color: #9fa4a6;
                            font-weight: normal;
                            height: 0.875rem;
                        }
                    }
                }
            }

            li {
                width: 100%;
                height: 4.375rem;
                a {
                    height: 100%;
                    padding: 1.375rem 1.25rem;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: #9fa4a6;
                    overflow: hidden;
                    &:hover {
                        cursor: pointer;
                        transform: scale(1.15, 1.15);
                        transition: all 0.3s;
                    }
                    .left {
                        display: flex;
                        align-items: center;
                        .icon {
                            width: 1.875rem;
                            height: 1.875rem;
                        }

                        .txt {
                            margin: 0 0.625rem;
                            font-size: 1.125rem;
                            color: #9fa4a6;
                            line-height: 1.625rem;
                            &:hover {
                                color: #5bcfc5;
                            }
                        }
                    }
                }
            }
        }
    }
}

.txt-active {
    color: #5bcfc5 !important;
    transition: all 0.3s;
}

.li-active {
    border-radius: 1.5625rem !important;
    background-color: rgba(91, 207, 197, 0.1);
    transition: all 0.3s;
}

.tab-reduce {
    .tab {
        .nav-header {
            a {
                // display: none !important;
                span {
                    display: none !important;
                }
            }
        }
    }
    .tab-body {
        padding: 0 10px !important;
        .profile {
            border: 0 !important;
            padding: 0 !important;
            margin: 0 !important;
            .user-text {
                display: none !important;
            }
        }
        li {
            a {
                padding: 0 5px !important;
                display: flex;
                justify-content: center !important;
                // border-radius: 50% !important;
                .left {
                    justify-content: center;
                    .txt {
                        display: none !important;
                    }
                }
                .icon-right {
                    display: none !important;
                }
            }
            .li-active {
                background-color: #fff !important;
            }
        }
        
    }
}

.arrow-icon-container {
    height: auto;
    padding: 0 1.25rem 1.25rem 1.25rem;
    .right {
        width: 2.25rem;
        height: 2.25rem;
        margin-right: 1.25rem;
    }
}
