header {
    // position: fixed;
    // top: .9375rem;
    // right: 0;
    height: 6.25rem;
    // width: calc(100% - 20.625rem);
    width: 100%;
    // padding: 0 0 0 330px;
    .head-bar {
        width: 100%;
        height: 100%;
        padding: 0 1.875rem 0 1.875rem;
        .nav-header {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            .header-left {
                display: flex;
                align-items: center;
                h1 {
                    font-size: 2.375rem;
                    color: #000;
                    font-weight: 400;
                    line-height: 6.25rem;
                }

                
            }
            .header-right {
                display: flex;
                align-items: center;
                .search-box {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 21.25rem;
                    height: 3.5rem;
                    border-radius: 0.9375rem;
                    background: rgba(255, 255, 255, 0.4);
                    border: 0.0625rem solid rgba(255, 255, 255, 0.5);
                    box-shadow: 0 0.3125rem 0.9375rem rgba(0, 0, 0, 0.05);
                    border-right: 0.0625rem solid rgba(255, 255, 255, 0.2);
                    border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.2);
                    padding: 0 0.9375rem;
                    .search {
                        width: 15.625rem;
                        border: 0;
                        background: rgba(255, 255, 255, 0.4);
                        &:focus {
                            outline: none;
                        }
                    }
                    .search-icon {
                        width: 1.875rem;
                        height: 1.875rem;
                    }
                }
                .action-bar {
                    display: flex;
                    align-items: center;
                    li {
                        padding: 0 0 0 1rem;
                        display: flex;
                        align-items: center;
                        a {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 3.625rem;
                            height: 3.625rem;
                        }
                    }
                }
                .generate {
                    width: 12.5rem;
                    height: 3.5rem;
                    border-radius: 1.125rem;
                    margin: 0 0 0 1rem;
                    border: 0.0625rem solid #5bcfc5;
                    background-color: #5bcfc5;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 1.25rem;

                    color: #fff;
                }
                .profile {
                    width: 8.125rem;
                    // height: 70px;
                    // margin: 0 0 20px;
                    border: 0.0625rem solid #f5f5f5;
                    border-radius: 1rem;
                    padding: 0.625rem 0.9375rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .left {
                        display: flex;
                        align-items: center;
                        .img-box {
                            width: 3.125rem;
                            height: 3.125rem;
                            border-radius: 50%;
                            // border: 1px solid #5bcfc5;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            overflow: hidden;

                            img {
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }

                        .user-text {
                            margin-left: 1rem;
                            height: 3.125rem;
                            display: flex;
                            flex-direction: column;
                            align-items: start;
                            justify-content: center;
                            p {
                                height: 1rem;
                                font-size: 1rem;
                            }
                            h6 {
                                font-size: 0.75rem;
                                margin: 0.3125rem 0 0;
                                color: #9fa4a6;
                                font-weight: normal;
                                height: 0.875rem;
                            }
                        }
                    }
                    .logout {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .logout-icon {
                            width: 2.25rem;
                            height: 2.25rem;
                        }
                    }
                }
            }
        }
    }
}
