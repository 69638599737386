* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

body {
    overflow: hidden;
}

section {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom, #f7f7fe, #dff1ff);
    .color {
        position: absolute;
        filter: blur(9.375rem);
    }
    .color:nth-child(1) {
        top: -21.875rem;
        width: 37.5rem;
        height: 37.5rem;
        background: #bf4ad4;
    }
    .color:nth-child(2) {
        left: 6.25rem;
        width: 31.25rem;
        height: 31.25rem;
        bottom: -9.375rem;
        background: #ffa500;
    }
    .color:nth-child(3) {
        right: 6.25rem;
        bottom: 3.125rem;
        width: 18.75rem;
        height: 18.75rem;
        background: #2b67f3;
    }
}

.box {
    position: relative;
    width: 25rem;
    height: 28.125rem;
    .square {
        position: absolute;
        border-radius: .625rem;
        backdrop-filter: blur(.3125rem);
        background: rgba(255, 255, 255, 0.1);
        animation-delay: calc(-1s * var(--i));
        animation: animate 10s linear infinite;
        box-shadow: 0 1.5625rem 2.8125rem rgba(0, 0, 0, 0.1);
        border: .0625rem solid rgba(255, 255, 255, 0.5);
        border-right: .0625rem solid rgba(255, 255, 255, 0.2);
        border-bottom: .0625rem solid rgba(255, 255, 255, 0.2);
        &:nth-child(1) {
            top: -3.125rem;
            left: -3.75rem;
            width: 6.25rem;
            height: 6.25rem;
        }
        &:nth-child(2) {
            z-index: 2;
            top: 9.375rem;
            left: -6.25rem;
            width: 7.5rem;
            height: 7.5rem;
        }
        &:nth-child(3) {
            z-index: 2;
            width: 5rem;
            height: 5rem;
            right: -3.125rem;
            bottom: -3.75rem;
        }
        &:nth-child(4) {
            left: 6.25rem;
            width: 3.125rem;
            height: 3.125rem;
            bottom: -5rem;
        }
        &:nth-child(5) {
            top: -5rem;
            left: 8.75rem;
            width: 3.75rem;
            height: 3.75rem;
        }
    }
}

@keyframes animate {
    0%,
    100% {
        transform: translateY(-2.5rem);
    }

    50% {
        transform: translateY(2.5rem);
    }
}

.container {
    width: 25rem;
    display: flex;
    min-height: 25rem;
    position: relative;
    border-radius: .625rem;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(.3125rem);
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 1.5625rem 2.8125rem rgba(0, 0, 0, 0.1);
    border: .0625rem solid rgba(255, 255, 255, 0.5);
    border-right: .0625rem solid rgba(255, 255, 255, 0.2);
    border-bottom: .0625rem solid rgba(255, 255, 255, 0.2);
}

.form {
    width: 100%;
    height: 100%;
    padding: 2.5rem;
    position: relative;
    &::placeholder {
        color: #fff;
    }
    h2 {
        color: #fff;
        font-size: 1.5rem;
        font-weight: 600;
        position: relative;
        letter-spacing: .0625rem;
        margin-bottom: 1.25rem;
        height: 1.75rem;
        // &::before {
        //     left: 0;
        //     width: 80px;
        //     height: 4px;
        //     content: "";
        //     bottom: -10px;
        //     background: #fff;
        //     position: absolute;
        // }
    }
    p {
        color: #fff;
        height: 1.75rem;
        letter-spacing: .0625rem;
        margin-bottom: 2.5rem;
        font-size: 1.125rem;
    }
    .input__box {
        width: 100%;
        height: 2.5625rem;
        margin-top: 1.25rem;

        // &:nth-child(3) {
        //     height: 67px;
        // }
    }
    .row {
        width: 100%;
        height: 2.5625rem;
        margin-top: 1.25rem;
        display: flex;
        .code {
            color: #666;
            cursor: pointer;
            background: #fff;
            padding: .625rem 1.25rem;
            max-width: 9.375rem;
            font-weight: 600;
            margin-bottom: 1.25rem;
            border-radius: 2.1875rem;
            margin-right: 1.25rem;
        }
    }
    input {
        width: 100%;
        color: #666;
        height: 2.5625rem;
        border: none;
        outline: none;
        font-size: 1rem;
        padding: .625rem 1.25rem;
        letter-spacing: .0625rem;
        border-radius: 2.1875rem;
        background: rgba(255, 255, 255, 0.2);
        border: .0625rem solid rgba(255, 255, 255, 0.5);
        box-shadow: 0 .3125rem .9375rem rgba(0, 0, 0, 0.05);
        border-right: .0625rem solid rgba(255, 255, 255, 0.2);
        border-bottom: .0625rem solid rgba(255, 255, 255, 0.2);
        &::placeholder {
            color: #9c9a9a;
        }
    }
    .submit {
        color: #666;
        cursor: pointer;
        background: #fff;
        padding: .625rem 1.25rem;
        width: 6.25rem;
        text-align: center;
        font-weight: 600;
        margin-bottom: 1.25rem;
        border-radius: 2.1875rem;
        margin-right: 1.25rem;
    }
}

.forget {
    color: #fff;
    margin-top: .3125rem;
    height: 1.25rem;
    a {
        color: #fff;
        font-weight: 600;
        text-decoration: none;
    }
}
