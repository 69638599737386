.entrance-container {
    width: 100%;
    display: flex;
    align-items: start;

    .menu-container {
        display: inline-block;
        width: 20.625rem;

        &.tab-reduce {
            width: 6.25rem;
            overflow: hidden;
            .tab-body{
                padding: 0 10px !important;
                .profile{
                    border: 0 !important;
                    padding:  0 !important;
                    margin: 0 !important;
                    .user-text{
                        display: none !important;
                    }
                }
                li{
                    a{
                        padding: 0 5px !important;
                        .left{
                            .txt{
                                display: none !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .page-route-container {
        display: inline-block;
        width: calc(100% - 20.625rem);

        &.page-enlarge {
            width: calc(100% - 6.25rem);
        }
    }
}
