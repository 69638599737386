code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
*,
body,
#root {
  height: 100%;
  box-sizing: border-box;
  font-family: AliMaMa !important;
  font-size: 16px;
}

@font-face {
  font-family: Lora;
  font-weight: 400;
  src: url("./fonts/Lora-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: GreatVibes;
  font-weight: 400;
  src: url("./fonts/GreatVibes-Regular.ttf") format("truetype");
}

@font-face {
  font-family: AliMaMa;
  font-weight: 400;
  src: url("./fonts/AlimamaAgileVF-Thin.woff2") format("truetype");
}
@font-face {
  font-family: Cairo;
  font-weight: 400;
  src: url("./fonts/Cairo-VariableFont_slntwght.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: Cairo !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f6f6;
}

input {
  font-size: 1rem !important;
}

@media screen and (max-width: 1024px) {
  html,
  *,
  body,
  #root {
    height: 100%;
    box-sizing: border-box;
    font-family: Cairo !important;
    font-size: 10px;
  }
}
