.content-body {
    width: 100%;
    height: calc(100% - 6.25rem) !important;
    .edit-contain {
        width: 100%;
        overflow-y: scroll;
        padding: 1.25rem 1.25rem;
        margin-left: -0.625rem;
        h1 {
            font-size: 1.5rem;
            font-weight: 500;
            height: 3.125rem;
        }
        .edit-form {
            padding: 3.125rem 3.125rem;
            background: #fff;
            margin-bottom: 1.875rem;
            border-radius: 1rem;
            box-shadow: 0 0.3125rem 0.3125rem 0 rgba(82, 63, 105, 0.05);
            height: auto;
            position: relative;
            .close-btn {
                position: absolute;
                top: 1.25rem;
                right: 1.25rem;
                height: auto;
            }
            .row {
                display: flex;
                height: auto;
                align-items: center;
                padding: 1.25rem 0;
                .title {
                    width: 20%;
                    font-size: 1.125rem;
                    text-align: right;
                    padding: 0 1.25rem;
                }
                .input {
                    width: 80%;
                    height: auto;
                    .control {
                        width: 100%;
                        background: #fff;
                        border: 0.0625rem solid #cecbcb;
                        padding: 0.3125rem 1.25rem;
                        color: #6e6e6e;
                        height: 3.5rem;
                        border-radius: 1rem;
                        &:focus {
                            outline: none;
                        }
                        &::placeholder {
                            font-size: 0.875rem;
                            color: #9e9d9d;
                        }
                    }
                    .textarea {
                        width: 100%;
                        background: #fff;
                        border: 0.0625rem solid #cecbcb;
                        padding: 0.9375rem 1.25rem;
                        color: #6e6e6e;
                        min-height: 5.625rem;
                        border-radius: 1rem;
                        &:focus {
                            outline: none;
                        }
                    }
                }

                .ID-box {
                    width: 80%;
                    height: auto;
                    .control {
                        width: 100%;
                        background: #fff;
                        border: 0.0625rem solid #cecbcb;
                        padding: 0.3125rem 1.25rem;
                        color: #6e6e6e;
                        height: 3.5rem;
                        border-radius: 1rem;
                        &:focus {
                            outline: none;
                        }
                        &::placeholder {
                            font-size: 0.875rem;
                            color: #9e9d9d;
                        }
                    }
                }
            }
            .prove {
                // padding: 20px 20px;
                // background: #fff;
                // margin-bottom: 30px;
                // border-radius: 16px;
                // box-shadow: 0 5px 5px 0 rgba(82, 63, 105, 0.05);
                // height: auto;
                // margin: 20px 0;

                .row {
                    padding: 0.625rem 0;
                    display: flex;

                    .title {
                        width: 20%;
                        font-size: 1.125rem;
                        text-align: right;
                        padding: 0 1.25rem;
                        // display: flex;
                        // flex-direction: column;
                        // align-items: center;
                    }
                    .file-box {
                        width: 80%;
                        .file {
                            width: 100%;
                            border: 0.0625rem solid #cecbcb;
                            // padding: 10px 10px;
                            height: 2.8125rem;
                            border-radius: 0.625rem;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            position: relative;
                            overflow: hidden;
                            p {
                                display: flex;
                                align-items: center;
                                padding: 0 1.25rem;
                                color: #969ba0;
                            }
                            .btn {
                                width: 6.25rem;
                                border-left: 0.0625rem solid #cecbcb;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                color: #969ba0;
                            }
                            .file-input {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                opacity: 0;
                            }
                        }
                        .img-row {
                            display: flex;
                            .img-box {
                                margin: 1.25rem 1.875rem 0.625rem 0;
                                width: 5.625rem;
                                height: 5.625rem;
                                border: 0.0625rem solid #cecbcb;
                                position: relative;
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: center;
                                align-items: center;
                                .remove {
                                    position: absolute;
                                    top: -0.625rem;
                                    right: -0.625rem;
                                    height: auto;
                                }
                                img {
                                    max-width: 100%;
                                    max-height: 100%;
                                }
                            }
                        }
                    }
                }
            }
            .submit {
                width: 6.25rem;
                height: auto;
                padding: 0.9375rem 1.25rem;
                background-color: #5bcfc5;
                color: #fff;
                margin: 3.125rem auto;
                text-align: center;
                font-size: 1.25rem;
                border-radius: 0.9375rem;
                transition: all 0.2s;
                &:hover {
                    background-color: #38bfb3;
                }
            }
        }
    }
}

.css-1nmdiq5-menu {
    height: auto;
}
.css-13cymwt-control {
    line-height: 2.375rem;
    height: 2.8125rem;
}
.css-1u9des2-indicatorSeparator {
    width: 0 !important;
}
