.content-body {
    width: 100%;
    height: calc(100% - 6.25rem);
    .commission-contain {
        width: 100%;
        overflow-y: scroll;
        padding: 1.25rem 1.25rem ;
        margin-left: -0.625rem;
        .commission-header {
            padding: 0.9375rem 1.875rem;
            background: #fff;
            margin-bottom: 1.875rem;
            border-radius: 1rem;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: end;
            .download {
                margin: 0 1.25rem;
            }
            .add-btn {
                .edit-icon {
                    width: 1.875rem;
                    height: 1.875rem;
                }
            }
        }
        .form-body {
            padding: 0.9375rem 1.25rem;
            background: #fff;
            margin-bottom: 1.875rem;
            border-radius: 1rem;
            height: auto;
            .form-header {
                height: auto;
                padding: 1.25rem 0.9375rem;
                border-bottom: 0.125rem solid #f5f5f5;
                display: flex;
                align-items: center;
                // text-align: center;
                .time {
                    width: 20%;
                    font-size: 1.125rem;
                    font-weight: 600;
                }
                .dollar {
                    width: 20%;
                    font-size: 1.125rem;
                    font-weight: 600;
                }
                .euro {
                    width: 20%;
                    font-size: 1.125rem;
                    font-weight: 600;
                }

                .pound {
                    width: 20%;
                    font-size: 1.125rem;
                    font-weight: 600;
                }
                .auer {
                    width: 20%;
                    font-size: 1.125rem;
                    font-weight: 600;
                }
            }
        }
        .form-contain {
            min-height: 31.25rem;
            padding-bottom: 1.875rem;
            .list {
                padding: 1.25rem 0.9375rem;
                border-bottom: 0.0625rem solid #f5f5f5;
                display: flex;
                // text-align: center;
                align-items: center;
                color: #969ba0;
                font-weight: 600;
                height: auto;
                font-size: 1.0625rem;
                .time {
                    width: 20%;
                }
                .dollar {
                    width: 20%;
                    span {
                        font-weight: 600;
                        font-size: 1.125rem;
                        color: #5bcfc5;
                    }
                }
                .euro {
                    width: 20%;
                    span {
                        font-weight: 600;
                        font-size: 1.125rem;
                        color: #5bcfc5;
                    }
                }
                .pound {
                    width: 20%;
                    span {
                        font-weight: 600;
                        font-size: 1.125rem;
                        color: #5bcfc5;
                    }
                }
                .auer {
                    width: 20%;
                    span {
                        font-weight: 600;
                        font-size: 1.125rem;
                        color: #5bcfc5;
                    }
                }
            }
        }

        // 分页
        .pagination {
            display: flex;
            justify-content: center;
            height: auto;

            .MuiPaginationItem-page.Mui-selected {
                background: #5bcfc5;
                border-color: #5bcfc5;
                box-shadow: 0 0.625rem 1.25rem 0 rgba(91, 207, 197, 0.2);
                color: #fff;
            }
        }
    }
}
