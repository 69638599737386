.content-body {
    width: 100%;
    height: calc(100% - 6.25rem) !important;
    .home-contain {
        width: 100%;
        overflow-y: scroll;
        height: 100%;
        padding: 1.25rem 0 0;
        .card-row {
            display: flex;
            align-items: center;
            height: auto;
            // flex-wrap: wrap;
            .card-col {
                width: 25%;
                padding: 0 0.9375rem;
                .card-body1 {
                    height: 9.875rem;
                    border-radius: 1.75rem;
                    background-color: #ffa755;
                    overflow: hidden;
                    padding: 2.1875rem 1.875rem 2.8125rem;
                    margin-bottom: 1.875rem;
                    position: relative;
                    display: flex;
                    .after {
                        position: absolute;
                        height: 7.5rem;
                        width: 7.5rem;
                        transform: rotate(40deg);
                        top: -2.1875rem;
                        right: -2.1875rem;
                        border-radius: 1.75rem;
                        background: -moz-linear-gradient(
                            left,
                            rgba(255, 255, 255, 0.15) 0,
                            rgba(255, 255, 255, 0) 100%
                        );
                        background: -webkit-linear-gradient(
                            left,
                            rgba(255, 255, 255, 0.15) 0,
                            rgba(255, 255, 255, 0) 100%
                        );
                        background: linear-gradient(to right, rgba(255, 255, 255, 0.15) 0, rgba(255, 255, 255, 0) 100%);
                    }
                    .icon {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 3.75rem;
                        height: 3.75rem;
                        line-height: 3.375rem;
                        border-radius: 50%;
                        background: rgba(255, 255, 255, 0.4);
                        .icon-suc {
                            width: 2rem;
                            height: 2rem;
                        }
                    }
                    .txt-box {
                        margin-left: 1.25rem;
                        h2 {
                            color: #fff;
                            margin: 0 0 0.3125rem;
                            font-size: 2.375rem;
                            font-weight: normal;
                            height: 2.8125rem;
                        }
                        p {
                            font-size: 1.125rem;
                            line-height: 1.5rem;
                            color: #fff;
                        }
                    }
                }
                .card-body2 {
                    height: 9.875rem;
                    border-radius: 1.75rem;
                    background-color: #68e365;
                    overflow: hidden;
                    padding: 2.1875rem 1.875rem 2.8125rem;
                    margin-bottom: 1.875rem;
                    position: relative;
                    display: flex;
                    .after {
                        position: absolute;
                        height: 9.375rem;
                        width: 9.375rem;
                        transform: rotate(40deg);
                        bottom: -2.1875rem;
                        right: -2.1875rem;
                        border-radius: 1.75rem;
                        background: -moz-linear-gradient(
                            left,
                            rgba(255, 255, 255, 0.15) 0,
                            rgba(255, 255, 255, 0) 100%
                        );
                        background: -webkit-linear-gradient(
                            left,
                            rgba(255, 255, 255, 0.15) 0,
                            rgba(255, 255, 255, 0) 100%
                        );
                        background: linear-gradient(to right, rgba(255, 255, 255, 0.15) 0, rgba(255, 255, 255, 0) 100%);
                    }
                    .icon {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 3.75rem;
                        height: 3.75rem;
                        line-height: 3.375rem;
                        border-radius: 50%;
                        background: rgba(255, 255, 255, 0.4);
                        .icon-suc {
                            width: 2.1875rem;
                            height: 2.1875rem;
                        }
                    }
                    .txt-box {
                        margin-left: 1.25rem;
                        h2 {
                            color: #fff;
                            margin: 0 0 0.3125rem;
                            font-size: 2.375rem;
                            font-weight: normal;
                            height: 2.8125rem;
                        }
                        p {
                            font-size: 1.125rem;
                            line-height: 1.5rem;
                            color: #fff;
                        }
                    }
                }
                .card-body3 {
                    height: 9.875rem;
                    border-radius: 1.75rem;
                    background-color: #ff5555;
                    overflow: hidden;
                    padding: 2.1875rem 1.875rem 2.8125rem;
                    margin-bottom: 1.875rem;
                    position: relative;
                    display: flex;
                    .after {
                        position: absolute;
                        height: 6.25rem;
                        width: 6.25rem;
                        transform: rotate(-45deg);
                        top: -2.1875rem;
                        right: 2.1875rem;
                        border-radius: 1.75rem;
                        background: -moz-linear-gradient(
                            left,
                            rgba(255, 255, 255, 0.15) 0,
                            rgba(255, 255, 255, 0) 100%
                        );
                        background: -webkit-linear-gradient(
                            left,
                            rgba(255, 255, 255, 0.15) 0,
                            rgba(255, 255, 255, 0) 100%
                        );
                        background: linear-gradient(to right, rgba(255, 255, 255, 0.15) 0, rgba(255, 255, 255, 0) 100%);
                    }
                    .icon {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 3.75rem;
                        height: 3.75rem;
                        line-height: 3.375rem;
                        border-radius: 50%;
                        background: rgba(255, 255, 255, 0.4);
                        .icon-suc {
                            width: 2.1875rem;
                            height: 2.1875rem;
                        }
                    }
                    .txt-box {
                        margin-left: 1.25rem;
                        h2 {
                            color: #fff;
                            margin: 0 0 0.3125rem;
                            font-size: 2.375rem;
                            font-weight: normal;
                            height: 2.8125rem;
                        }
                        p {
                            font-size: 1.125rem;
                            line-height: 1.5rem;
                            color: #fff;
                        }
                    }
                }
                .card-body4 {
                    height: 9.875rem;
                    border-radius: 1.75rem;
                    background-color: #709fba;
                    overflow: hidden;
                    padding: 2.1875rem 1.875rem 2.8125rem;
                    margin-bottom: 1.875rem;
                    position: relative;
                    display: flex;
                    .after {
                        position: absolute;
                        height: 6.25rem;
                        width: 6.25rem;
                        transform: rotate(145deg);
                        bottom: -2.1875rem;
                        right: 0;
                        border-radius: 1.75rem;
                        background: -moz-linear-gradient(
                            left,
                            rgba(255, 255, 255, 0.15) 0,
                            rgba(255, 255, 255, 0) 100%
                        );
                        background: -webkit-linear-gradient(
                            left,
                            rgba(255, 255, 255, 0.15) 0,
                            rgba(255, 255, 255, 0) 100%
                        );
                        background: linear-gradient(to right, rgba(255, 255, 255, 0.15) 0, rgba(255, 255, 255, 0) 100%);
                    }
                    .icon {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 3.75rem;
                        height: 3.75rem;
                        line-height: 3.375rem;
                        border-radius: 50%;
                        background: rgba(255, 255, 255, 0.4);
                        .icon-suc {
                            width: 2rem;
                            height: 2rem;
                        }
                    }
                    .txt-box {
                        margin-left: 1.25rem;
                        h2 {
                            color: #fff;
                            margin: 0 0 0.3125rem;
                            font-size: 2.375rem;
                            font-weight: normal;
                            height: 2.8125rem;
                        }
                        p {
                            font-size: 1.125rem;
                            line-height: 1.5rem;
                            color: #fff;
                        }
                    }
                }
            }
        }
        .sec-row {
            display: flex;
            align-items: center;
            height: auto;
            padding: 0 0.9375rem;
            .left-card {
                width: 70%;
                margin-bottom: 1.875rem;
                background-color: #fff;
                transition: all 0.5s ease-in-out;
                position: relative;
                border: 0 solid transparent;
                border-radius: 1.75rem;
                box-shadow: 0 0.3125rem 0.3125rem 0 rgba(82, 63, 105, 0.05);
                height: 20.625rem;
                .card-body {
                    padding: 1.875rem;
                    display: flex;
                    .left {
                        width: 50%;
                        position: relative;
                        z-index: 1;
                        border-radius: 1.75rem;
                        overflow: hidden;
                        padding: 1.875rem 2.1875rem;
                        display: flex;
                        background: #496ecc;
                        background-image: url("../image/pattern6.png");
                        .card-info {
                            flex: 1;
                            padding-top: 1.875rem;
                            .img {
                                height: 2.25rem;
                                margin: 0 0 1.5rem;
                                img {
                                    max-height: 100%;
                                    animation: circle 1s linear infinite;
                                }
                            }
                            .card-balance {
                                font-size: 2.25rem;
                                color: #f56f6f;
                                font-weight: 600;
                                height: 2.75rem;
                                margin: 0 0 0.5rem;
                            }
                            .bal-txt {
                                color: #fff;
                                font-size: 1rem;
                                height: 1.5625rem;
                                margin: 0 0 1rem;
                                font-weight: normal;
                            }
                            .span-txt {
                                font-style: 0.75rem;
                                color: #fff;
                                font-weight: 200;
                            }
                        }
                        .change-btn {
                            writing-mode: tb;
                            position: relative;
                            font-weight: 600;
                            background: #fff;
                            color: #000;
                            border-radius: 1.25rem;
                            transform: rotate(-180deg);
                            font-size: 1.0625rem;
                            font-weight: 400;
                            padding: 3.125rem 1.4375rem;
                            &:hover {
                                cursor: pointer;
                            }
                            .change {
                                position: absolute;
                                bottom: 1.25rem;
                                width: 1.25rem;
                                height: 1.25rem;
                                transition: all 0.6s;
                            }
                            .down {
                                position: absolute;
                                top: 1.25rem;
                                width: 1.25rem;
                                height: 1.25rem;
                                transform: rotate(180deg);
                            }
                            &::after {
                                content: "";
                                position: absolute;
                                right: 0.625rem;
                                z-index: -1;
                                top: 50%;
                                transform: translateY(-50%);
                                width: 100%;
                                height: 90%;
                                border-radius: inherit;
                                background: rgba(255, 255, 255, 0.3);
                            }
                        }
                    }
                    .right {
                        width: 50%;
                        display: flex;
                        padding: 0 0.9375rem;
                        .data-box {
                            width: 50%;
                            padding: 0 0.9375rem;
                            .card-title {
                                font-size: 1.25rem;
                                font-weight: 400;
                                color: #000;
                                text-transform: capitalize;
                                height: auto;
                            }
                            .span-title {
                                color: #969ba0;
                                font-size: 0.8125rem;
                                margin-top: 0.3125rem;
                                display: block;
                                height: auto;
                            }
                            .card-list {
                                margin-top: 1.25rem;
                                height: auto;
                                li {
                                    display: flex;
                                    align-items: center;
                                    color: #000;
                                    margin-bottom: 0.9375rem;
                                    font-weight: 400;
                                    height: 1.3125rem;
                                    position: relative;
                                    font-size: 1rem;
                                    .circle {
                                        display: inline-block;
                                        height: 1.1875rem;
                                        width: 1.1875rem;
                                        border-radius: 1.1875rem;
                                        margin-right: 0.625rem;
                                        margin-left: 0;
                                    }
                                    .left {
                                        display: flex;
                                    }
                                    .l1 {
                                        background-color: #68e365;
                                    }
                                    .l2 {
                                        background-color: #f72b50;
                                    }
                                    .value {
                                        margin-left: 9.375rem;
                                        font-weight: 500;
                                        color: #969ba0;
                                        position: absolute;
                                        right: 0;
                                        font-size: 1.125rem;
                                    }
                                    .l3 {
                                        background-color: #5470c6;
                                    }
                                }
                            }
                        }
                        .chart {
                            width: 50%;
                            .pie-chart {
                                width: 100% !important;
                                height: 100% !important;
                            }
                        }
                    }
                }
            }
            .right-card {
                width: 30%;
                margin-bottom: 1.875rem;
                margin-left: 1.875rem;
                background-color: #fff;
                transition: all 0.5s ease-in-out;
                border: 0 solid transparent;
                border-radius: 1.75rem;
                box-shadow: 0 0.3125rem 0.3125rem 0 rgba(82, 63, 105, 0.05);
                height: 20.625rem;
                .box {
                    width: 100%;
                    height: 100%;
                    // padding: 30px;
                    .table {
                        width: 100%;
                        height: 100%;
                        .title {
                            width: 100%;
                            height: auto;
                            padding: 1.5rem 1.875rem 1.25rem;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            .text-box {
                                h3 {
                                    height: auto;
                                    font-size: 1.25rem;
                                    margin: 0 0 0.5rem;
                                    font-weight: normal;
                                }
                                .txt {
                                    color: #969ba0;
                                    font-size: 0.75rem;
                                }
                            }

                            .tab {
                                display: flex;
                                align-items: center;
                                border-bottom: 0.25rem solid #f4f5f9;
                                li {
                                    // margin: 0 0 -4px;
                                    color: #969ba0;
                                    font-size: 0.875rem;
                                    padding: 0.5rem 1rem;
                                    position: relative;
                                    &:hover {
                                        color: #5bcfc5;
                                    }
                                }
                                .active {
                                    display: block;
                                    position: relative;
                                    width: 100%;
                                    &::before {
                                        content: "";
                                        position: absolute;
                                        height: 0.25rem;
                                        background: #5bcfc5;
                                        width: 100%;
                                        left: 50%;
                                        bottom: -0.25rem;
                                        border-radius: 0.3125rem;
                                        transform: translateX(-50%);
                                        transition: all 1s;
                                    }
                                }
                            }
                        }
                        .body {
                            width: 100%;
                            height: 12.5rem;
                            .row {
                                width: 100%;
                                height: auto;
                                padding: 1.25rem 1.875rem;
                                display: flex;
                                align-items: center;

                                .svg {
                                    border-radius: 1.5625rem;
                                    width: 3.9375rem;
                                    height: 3.9375rem;
                                    margin-right: 1.25rem;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                }
                                .error {
                                    background: #fee6ea;
                                    border-color: #fee6ea;
                                }
                                .success {
                                    background: #e7fbe6;
                                    border-color: #e7fbe6;
                                }
                                .title-p {
                                    font-size: 1.875rem;
                                    // font-weight: 600;
                                    margin-right: 1.875rem;
                                }
                                .suc {
                                    font-size: 1.875rem;
                                    font-weight: 600;
                                    color: green;
                                }
                                .err {
                                    font-size: 1.875rem;
                                    font-weight: 600;
                                    color: red;
                                }
                            }
                        }
                    }
                }
            }
        }
        .third-row {
            display: flex;
            align-items: center;
            height: auto;

            .left {
                width: 35%;
                padding: 0.9375rem;
                height: 100%;
                // height: auto;
                .col {
                    width: 100%;
                    background-color: #fff;
                    transition: all 0.5s ease-in-out;
                    position: relative;
                    border: 0 solid transparent;
                    border-radius: 1.75rem;
                    box-shadow: 0 0.3125rem 0.3125rem 0 rgba(82, 63, 105, 0.05);
                    .card-header {
                        position: relative;
                        background: transparent;
                        padding: 2.125rem 1.875rem 0;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        height: auto;
                        .title {
                            h4 {
                                font-size: 1.25rem;
                                font-weight: normal;
                                height: auto;
                            }
                            span {
                                color: #969ba0;
                                font-size: 0.875rem;
                                height: auto;
                            }
                        }
                        .icon {
                            width: 2.5rem;
                            height: 2.5rem;
                        }
                    }
                    .card-body {
                        width: 100%;
                        height: auto;
                        padding: 2.5rem;
                        .card {
                            margin: 0 -1.125rem;
                            padding: 1.125rem 1.125rem;
                            border-radius: 1.75rem;
                            background: rgba(91, 207, 197, 0.1);
                            display: flex;
                            align-items: center;
                            .actor {
                                width: 3.75rem;
                                height: 3.75rem;
                                border-radius: 1.25rem;
                                overflow: hidden;
                                img {
                                    max-width: 100%;
                                    max-height: 100%;
                                }
                            }
                            .txt {
                                margin: 0 0.9375rem;
                                width: 9.375rem;
                                height: auto;
                                h4 {
                                    font-size: 1rem;
                                    height: 1.25rem;
                                }
                                span {
                                    color: #969ba0;
                                    font-size: 0.875rem;
                                }
                            }
                        }
                        .slider-box {
                            width: 100%;
                            // height: 120px;
                            // border:1px solid  #5470c6;
                            padding: 1.25rem 0;
                            h5 {
                                height: auto;
                                font-size: 1.125rem;
                                font-weight: 500;
                                margin: 0 0 0.625rem;
                            }
                            h4 {
                                height: auto;
                                font-size: 1.875rem;
                                font-weight: normal;
                                text-align: center;
                                margin: 0 0 0.9375rem;
                            }
                            .MuiSlider-root {
                                .MuiSlider-rail {
                                    border-radius: 0.1875rem !important;
                                    background: #d2d6de !important;
                                }
                                .MuiSlider-thumb {
                                    width: 0.9375rem !important;
                                    height: 0.9375rem !important;
                                    background-color: #5bcfc5 !important;
                                    margin-top: -0.4375rem !important;
                                }
                                .MuiSlider-track {
                                    background-color: #5bcfc5 !important;
                                }
                            }
                        }
                        .your-balance {
                            margin: 0.9375rem 0;
                            display: flex;
                            justify-content: space-between;
                            .txt {
                                font-size: 1rem;
                                color: #709fba;
                                font-weight: 500;
                            }
                        }
                        .btn {
                            background: transparent;
                            padding-top: 2.5rem;
                            // height: 80px;
                            .btn-box {
                                width: 100%;
                                padding: 1rem 2rem;
                                background: #5bcfc5;
                                border: 0.0625rem solid #5bcfc5;
                                border-radius: 1.25rem;
                                color: #fff;
                                font-size: 1.125rem;
                                text-align: center;
                                height: 3.75rem;
                                line-height: 1.6875rem;
                                transition: all 0.3s;
                                &:hover {
                                    transform: scale(1.05);
                                }
                            }
                        }
                    }
                }
            }
            .middle {
                width: 35%;
                padding: 0.9375rem;
                height: 100%;
                .col {
                    width: 100%;
                    height: 100%;
                    background-color: #fff;
                    transition: all 0.5s ease-in-out;
                    position: relative;
                    border: 0 solid transparent;
                    border-radius: 1.75rem;
                    box-shadow: 0 0.3125rem 0.3125rem 0 rgba(82, 63, 105, 0.05);
                    .card-header {
                        position: relative;
                        background: transparent;
                        padding: 2.125rem 1.875rem 0;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        height: auto;
                        .title {
                            h4 {
                                font-size: 1.25rem;
                                font-weight: normal;
                                height: 1.5rem;
                            }
                            span {
                                color: #969ba0;
                                font-size: 0.875rem;
                                height: 1rem;
                            }
                        }
                        .icon {
                            width: 2.25rem;
                            height: 2.25rem;
                        }
                    }
                    .card-body {
                        width: 100%;
                        height: auto;
                        padding: 2.5rem 1.875rem;
                        .recode {
                            // display: none;
                            height: 25rem;
                            overflow-y: scroll;
                            .recode-li {
                                display: flex;
                                justify-content: space-between;
                                padding: 0.625rem 0;
                                height: 3.125rem;

                                .time {
                                    color: #6d7c8a;
                                    font-size: 1rem;
                                }
                                .amount {
                                    color: #e04125;
                                    font-size: 1.125rem;
                                }
                            }
                        }
                        .non-recode {
                            height: 18.75rem;
                            padding: 1.25rem;
                            border-radius: 1.25rem;
                            .non {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                padding-left: 1.25rem;
                            }
                        }
                        .recode-btn {
                            background: transparent;
                            padding-top: 2.5rem;
                            .recode-btn-box {
                                width: 100%;
                                padding: 1rem 2rem;

                                border: 0.0625rem solid #5bcfc5;
                                border-radius: 1.25rem;
                                color: #5bcfc5;
                                font-size: 1.125rem;
                                text-align: center;
                                height: 3.75rem;
                                line-height: 1.6875rem;
                                transition: all 0.2s;
                                &:hover {
                                    color: #fff;
                                    background: #5bcfc5;
                                }
                            }
                        }
                    }
                }
            }
            .right {
                width: 30%;
                padding: 0.9375rem;
                height: 100%;
                .col {
                    width: 100%;
                    height: 100%;
                    background-color: #fff;
                    transition: all 0.5s ease-in-out;
                    position: relative;
                    border: 0 solid transparent;
                    border-radius: 1.75rem;
                    box-shadow: 0 0.3125rem 0.3125rem 0 rgba(82, 63, 105, 0.05);
                    .card-header {
                        position: relative;
                        background: transparent;
                        padding: 2.125rem 1.875rem 0;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        height: auto;
                        .title {
                            h4 {
                                font-size: 1.25rem;
                                font-weight: normal;
                                height: auto;
                            }
                            span {
                                color: #969ba0;
                                font-size: 0.875rem;
                                height: auto;
                            }
                        }
                        .icon {
                            width: 2.25rem;
                            height: 2.25rem;
                        }
                    }
                    .card-body {
                        width: 100%;
                        height: auto;
                        padding: 2.5rem 1.875rem;
                        .recode {
                            // display: none;
                            height: 25rem;
                            overflow-y: scroll;
                            .li {
                                display: flex;
                                justify-content: space-between;
                                padding: 0.625rem 0rem;
                                align-items: center;
                                height: auto;
                                .country-flag {
                                    width: 3rem;
                                    height: 1.875rem;
                                    // border: 1px solid #6d7c8a;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    img {
                                        max-width: 100%;
                                        max-height: 100%;
                                    }
                                }
                                .num {
                                    font-size: 1rem;
                                    font-weight: 600;
                                }
                            }
                        }
                        .non-recode {
                            height: 18.75rem;
                            padding: 1.25rem;
                            border-radius: 1.25rem;
                            .non {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                padding-left: 1.25rem;
                            }
                        }
                        .recode-btn {
                            background: transparent;
                            padding-top: 27.5rem;
                            .recode-btn-box {
                                width: 100%;
                                padding: 1rem 2rem;

                                border: 0.0625rem solid #5bcfc5;
                                border-radius: 1.25rem;
                                color: #5bcfc5;
                                font-size: 1.125rem;
                                text-align: center;
                                height: 3.75rem;
                                line-height: 1.6875rem;
                                transition: all 0.2s;
                                &:hover {
                                    color: #fff;
                                    background: #5bcfc5;
                                }
                            }
                        }
                    }
                }
            }
        }
        .fourth-row {
            display: flex;
            align-items: center;
            height: auto;

            .col-left {
                width: 50%;
                padding: 0.625rem;
                .table {
                    width: 100%;
                    background-color: #fff;
                    transition: all 0.5s ease-in-out;
                    position: relative;
                    border: 0 solid transparent;
                    border-radius: 1.75rem;
                    box-shadow: 0 0.3125rem 0.3125rem 0 rgba(82, 63, 105, 0.05);
                    .table-header {
                        width: 100%;
                        height: 5.625rem;
                        padding: 1.5rem 1.875rem 1.25rem;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .title {
                            h3 {
                                height: auto;
                                font-size: 1.25rem;
                                margin: 0 0 0.5rem;
                                font-weight: normal;
                            }
                            .txt {
                                color: #969ba0;
                                font-size: 0.75rem;
                            }
                        }
                        .tab {
                            display: flex;
                            align-items: center;
                            border-bottom: 0.25rem solid #f4f5f9;
                            li {
                                // margin: 0 0 -4px;
                                color: #969ba0;
                                font-size: 0.875rem;
                                padding: 0.5rem 1rem;
                                position: relative;
                                &:hover {
                                    color: #5bcfc5;
                                }
                            }
                            .active {
                                display: block;
                                position: relative;
                                width: 100%;
                                &::before {
                                    content: "";
                                    position: absolute;
                                    height: 0.25rem;
                                    background: #5bcfc5;
                                    width: 100%;
                                    left: 50%;
                                    bottom: -0.25rem;
                                    border-radius: 0.3125rem;
                                    transform: translateX(-50%);
                                    transition: all 1s;
                                }
                            }
                        }
                    }
                    .table-body {
                        width: 100%;
                        height: 31.25rem;
                        padding-bottom: 3.125rem;
                        overflow: hidden;
                        .li-body {
                            width: 100%;
                            height: 28.125rem;
                            // border: 1px solid red;
                            overflow-y: scroll;
                            .table-li {
                                height: 5.75rem;
                                border-bottom: 0.0625rem solid #f5f5f5;
                                padding: 0.9375rem 1.875rem;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                .svg {
                                    border-radius: 1.5625rem;
                                    width: 3.9375rem;
                                    height: 3.9375rem;
                                    margin-right: 1.25rem;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                }
                                .error {
                                    background: #fee6ea;
                                    border-color: #fee6ea;
                                }
                                .success {
                                    background: #e7fbe6;
                                    border-color: #e7fbe6;
                                }
                                .name {
                                    width: 15.625rem;
                                    height: 100%;
                                    padding: 0 0.625rem;
                                    h6 {
                                        height: 1.875rem;
                                        font-size: 1rem;
                                        font-weight: 600;
                                        line-height: 1.875rem;
                                    }
                                    span {
                                        font-size: 0.8125rem;
                                        color: #969ba0;
                                    }
                                }
                                .time {
                                    width: 9.375rem;
                                    height: 100%;
                                    padding: 0 0.625rem;
                                    h6 {
                                        height: 1.875rem;
                                        font-size: 1rem;
                                        font-weight: 600;
                                        line-height: 1.875rem;
                                        color: #969ba0;
                                    }
                                    span {
                                        font-size: 0.8125rem;
                                        color: #969ba0;
                                    }
                                }
                                .price {
                                    width: 9.375rem;
                                    height: 100%;
                                    padding: 0 0.625rem;
                                    font-size: 1rem;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    color: #68e365;
                                    font-size: 1rem;
                                    font-weight: 600;
                                }
                                .status {
                                    width: 10rem;
                                    height: 100%;
                                    font-size: 1rem;
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-end;
                                }
                                .cancel {
                                    color: #f72b50;
                                }
                                .complete {
                                    color: #68e365;
                                }
                                .pending {
                                    color: #969ba0;
                                }
                            }
                        }
                    }
                }
            }
            .col-right {
                width: 50%;
                padding: 0.9375rem;
                height: 38.75rem;
                .table {
                    width: 100%;
                    background-color: #fff;
                    transition: all 0.5s ease-in-out;
                    position: relative;
                    border: 0 solid transparent;
                    border-radius: 1.75rem;
                    box-shadow: 0 0.3125rem 0.3125rem 0 rgba(82, 63, 105, 0.05);
                    padding: 0.625rem;
                    .overview {
                        height: 3.75rem;
                        font-size: 1.25rem;
                        padding: 0.625rem;
                        p {
                            margin: 0.3125rem 0 0;
                            font-size: 0.875rem;
                            color: #6d7c8a;
                        }
                    }
                    .charge-chart {
                        width: 100%;
                        height: 31.25rem;
                        .chart {
                            width: 100% !important;
                            height: 100% !important;
                        }
                    }
                }
            }
        }

        .fifth-row {
            display: flex;
            align-items: center;
            height: auto;
            .col {
                width: 50%;
                padding: 0.9375rem;
                height: 38.75rem;
                .table {
                    width: 100%;
                    background-color: #fff;
                    transition: all 0.5s ease-in-out;
                    position: relative;
                    border: 0 solid transparent;
                    border-radius: 1.75rem;
                    box-shadow: 0 0.3125rem 0.3125rem 0 rgba(82, 63, 105, 0.05);
                    padding: 0.625rem;
                    .overview {
                        height: 3.75rem;
                        font-size: 1.25rem;
                        padding: 0.625rem;
                        p {
                            margin: 0.3125rem 0 0;
                            font-size: 0.875rem;
                            color: #6d7c8a;
                        }
                    }
                    .charge-chart {
                        width: 100%;
                        height: 31.25rem;
                        .chart {
                            width: 100% !important;
                            height: 100% !important;
                        }
                    }
                }
            }
        }
    }
}

@keyframes circle {
    0% {
        transform: rotateY(0deg);
    }
    25% {
        transform: rotateY(90deg);
    }
    50% {
        transform: rotateY(180deg);
    }
    100% {
        transform: rotateY(360deg);
    }
}

.charge-box {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 15;
    .row {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .box {
            width: 31.25rem;
            height: 18.75rem;
            background-color: #fff;
            border-radius: 1.5625rem;
            overflow: hidden;
            .head {
                width: 100%;
                height: 3.75rem;
                background-color: #5bcfc5;
                padding: 1.25rem;
                font-size: 1.25rem;
                font-weight: 600;
                color: #fff;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .input-row {
                width: 100%;
                height: auto;
                padding: 0.625rem 1.25rem;
                p {
                    font-size: 1.0625rem;
                    color: #5bcfc5;
                }
                input {
                    margin: 0.3125rem 0 0;
                    width: 100%;
                    border: 0.0625rem solid #5bcfc5;
                    padding: 0.3125rem 0.625rem;
                    border-radius: 0.375rem;
                    &:focus {
                        outline: none;
                    }
                }
            }
            .btn-row {
                width: 100%;
                margin: 0.625rem 0 0;
                padding: 0.3125rem 3.125rem;
                height: auto;
                display: flex;
                justify-content: space-between;
                .btn {
                    padding: 0.5rem 0.625rem;
                    background-color: #5bcfc5;
                    color: #fff;
                    font-size: 1.25rem;
                    border-radius: 0.5625rem;
                    cursor: pointer;
                    &:hover {
                        background-color: #1b8a80;
                    }
                }
                .cancel {
                    padding: 0.5rem 0.625rem;
                    background-color: #fff;
                    border: 0.0625rem solid #5bcfc5;
                    color: #5bcfc5;
                    font-size: 1.25rem;
                    border-radius: 0.5625rem;
                    cursor: pointer;
                    &:hover {
                        background-color: #5bcfc5;
                        color: #fff;
                    }
                }
            }
        }
    }
}

.recharge-show {
    display: block !important;
}
