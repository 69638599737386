.footer{
    width: 100%;
    margin-top: 1.25rem;
    margin-bottom: 2.5rem;
    height: auto;
    .footer-text{
        color: #969ba0;
        font-size: .875rem !important;
        font-weight: normal;
        text-align: center;
    }
}