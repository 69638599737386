.pop-box {
  display: none;
  position: fixed;
  top: 18%;
  left: 45%;
  height: auto;
  padding: 20px;
  background: rgba(4, 217, 245, 0.1);
  animation-delay: calc(-1s * var(--i));
  animation: animate1 6s linear ;
  box-shadow: 0 1.5625rem 2.8125rem rgba(0, 0, 0, 0.1);
  border: .0625rem solid rgba(255, 255, 255, 0.5);
  border-right: .0625rem solid rgba(255, 255, 255, 0.2);
  border-bottom: .0625rem solid rgba(255, 255, 255, 0.2);
  z-index: 20;
  min-width: 200px;
  border-radius: 15px;
  text-align: center;
  box-shadow: 0 0.3125rem 0.3125rem 0 rgba(82, 63, 105, 0.05);
  // color: rgb(15, 15, 15);
}

.pop-show {
  display: block !important;
}


@keyframes animate1 {
  0%,
  100% {
      transform: translateY(-6.25rem);
  }

  50% {
      transform: translateY(3.125rem);
  }
}