.content-body {
    width: 100%;
    height: calc(100% - 6.25rem) !important;
    .table-contain {
        width: 100%;
        padding: 1.25rem 1.25rem;
        margin-left: -0.625rem;
        overflow-y: scroll;
        .table-header {
            padding: 0.9375rem 1.875rem;
            background: #fff;
            margin-bottom: 1.875rem;
            border-radius: 1rem;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .select-box {
                background: #fff;
                border: 0.125rem solid #ddd;
                padding: 0.75rem 0.9375rem;
                color: #6e6e6e;
                border-radius: 0.375rem;
                display: flex;
                align-items: center;
                font-size: 0.9375rem;
                position: relative;
                box-shadow: 0 0.3125rem 0.3125rem 0 rgba(82, 63, 105, 0.05);
                font-weight: 600;
                span {
                    margin-right: 0.625rem;
                }
                .select-body {
                    display: none;
                    position: absolute;
                    top: 2.8125rem;
                    left: 0;
                    padding: 0.3125rem;
                    border: 0.0625rem solid #d6d4d4;
                    border-radius: 0.3125rem;
                    width: 13.75rem;
                    height: auto;
                    background-color: #fff;
                    z-index: 11;
                    li {
                        height: auto;
                        padding: 0.3125rem 0.9375rem;
                        font-size: 0.875rem;
                        color: #6e6e6e;
                        &:hover {
                            background-color: #8cddd6;
                            color: #fff;
                        }
                    }
                }
            }
            .right {
                display: flex;
                align-items: center;
                .download {
                    margin: 0 1.25rem;
                }
                .add-btn {
                    .edit-icon {
                        width: 1.875rem;
                        height: 1.875rem;
                    }
                }
            }
        }
        .form-body {
            padding: 0.9375rem 1.25rem;
            background: #fff;
            margin-bottom: 1.875rem;
            border-radius: 1rem;
            height: auto;
            // height: 400px;
            .form-header {
                height: auto;
                padding: 1.25rem 0.9375rem;
                border-bottom: 0.125rem solid #f5f5f5;
                display: flex;
                align-items: center;
                text-align: center;
                .time {
                    width: 10%;
                    font-size: 1.125rem;
                    font-weight: 600;
                }
                .state {
                    width: 10%;
                    font-size: 1.125rem;
                    font-weight: 600;
                }
                .operation {
                    width: 10%;
                    font-size: 1.125rem;
                    font-weight: 600;
                }
                .email {
                    width: 20%;
                    font-size: 1.125rem;
                    font-weight: 600;
                }
                .vps {
                    width: 8%;
                    font-size: 1.125rem;
                    font-weight: 600;
                }
                .note {
                    width: 10%;
                    font-size: 1.125rem;
                    font-weight: 600;
                }
                .note2 {
                    width: 15%;
                    font-size: 1.125rem;
                    font-weight: 600;
                }
                .register {
                    width: 10%;
                    font-size: 1.125rem;
                    font-weight: 600;
                }
                .country {
                    width: 10%;
                    font-size: 1.125rem;
                    font-weight: 600;
                }
                .sk {
                    width: 5%;
                    font-size: 1.125rem;
                    font-weight: 600;
                }
                .pk {
                    width: 5%;
                    font-size: 1.125rem;
                    font-weight: 600;
                }
            }
            .form-contain {
                min-height: 31.25rem;
                padding-bottom: 1.875rem;
                .list {
                    padding: 1.25rem 0.9375rem;
                    border-bottom: 0.0625rem solid #ddd;
                    display: flex;
                    text-align: center;
                    align-items: center;
                    color: #000;
                    font-weight: 600;
                    height: auto;
                    font-size: 0.75rem;
                    .time {
                        width: 10%;
                        font-size: .875rem;
                    }
                    .status {
                        width: 10%;
                        padding: 0 0.625rem;
                        font-size: .875rem;
                        p {
                            padding: 0.625rem 0.9375rem;
                            border-radius: 1.5625rem;
                            height: auto;
                            color: #000;
                            background-color: #fff;
                            font-size: 0.75rem;
                        }
                        .err {
                            background-color: #fee6ea;
                            color: #f72b50;
                        }
                        .suc {
                            background-color: #e7fbe6;
                            color: #68e365;
                        }
                        .pending {
                            background-color: #fff6ee;
                            color: #ffa755;
                        }
                        .acc {
                            background-color: #e9e9f7;
                            color: #6c8bf0;
                        }
                    }
                    .operation {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                        padding: 0 1.25rem;
                        position: relative;
                        .action-btn {
                            width: 3.125rem;
                            height: 3.125rem;
                            background-color: #fff6ee;
                            color: #ffa755;
                            border-radius: 50%;
                        }
                        .operation-ul {
                            display: none;
                            height: auto;
                            position: absolute;
                            right: -2.8125rem;
                            top: 3.125rem;
                            border: 0.0625rem solid #969ba0;
                            background-color: #fff;
                            width: 13.25rem;
                            padding: 0.3125rem;
                            border-radius: 1rem;
                            box-shadow: 0 0.3125rem 0.3125rem 0 rgba(82, 63, 105, 0.05);
                            z-index: 12;
                            li {
                                padding: 0.125rem 0.3125rem;
                                height: auto;
                                text-align: left;
                                font-size: .8125rem;
                                &:hover {
                                    background-color: #5bcfc5;
                                    color: #fff;
                                }
                            }
                        }
                    }
                    .email {
                        width: 20%;
                        padding: 0 20px;
                        overflow: hidden;
                        white-space: wrap;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 4;
                        -webkit-box-orient: vertical;
                        word-break: break-all;
                        font-size: .875rem;
                    }
                    .vps {
                        width: 8%;
                        font-size: .875rem;
                    }
                    .note {
                        width: 10%;
                        padding: 0 0.625rem;
                        display: flex;
                        justify-content: center;
                        font-size: .875rem;
                        word-break: break-all;
                    }
                    .note2 {
                        width: 15%;
                        padding: 0 0.625rem;
                        display: flex;
                        justify-content: center;
                        font-size: .875rem;
                    }
                    .register {
                        width: 10%;
                        font-size: .875rem;
                        .quick {
                            color: #68e365;
                        }
                        .slow {
                            color: #f72b50;
                        }
                    }
                    .country {
                        width: 10%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 1.25rem;
                        img, div {
                            width: 3.5rem;
                            height: 2.5rem;
                            border: 0.0625rem solid #f5f5f5;
                        }
                        .img {
                            width: 3.5rem;
                            height: 2.5rem;
                            border: 0.0625rem solid #e4e2e2;
                            background-color: #ffffff;
                        }
                    }
                    .pk {
                        width: 5%;
                        padding: 0 0.625rem;
                        display: flex;
                        justify-content: center;
                        .pk-btn {
                            width: 2.5rem;
                            height: 2.5rem;
                            border-radius: 50%;
                            background-color: #e5f5f3;
                        }
                    }
                    .sk {
                        width: 5%;
                        padding: 0 0.625rem;
                        display: flex;
                        justify-content: center;
                        .sk-btn {
                            width: 2.5rem;
                            height: 2.5rem;
                            border-radius: 50%;
                            background-color: #e5f5f3;
                        }
                    }
                }
            }
        }

        // 分页
        .pagination {
            display: flex;
            justify-content: center;
            height: auto;

            .MuiPaginationItem-page.Mui-selected {
                background: #5bcfc5;
                border-color: #5bcfc5;
                box-shadow: 0 0.625rem 1.25rem 0 rgba(91, 207, 197, 0.2);
                color: #fff;
            }
            .MuiPagination-ul {
                .MuiPaginationItem-root {
                    // height: 1.875rem;
                    // min-width: 2rem;
                    // padding: 0 .375rem;
                }
            }
        }
    }
}

.select-show {
    display: block !important;
}

.operate-show {
    display: block !important;
}

@media screen and (max-width: 1024px) {
    .content-body {
        .table-contain {
            .form-body {
                .form-contain {
                    .list {
                        .operation {
                            .operation-ul {
                                width: 210px !important;
                                li {
                                    height: auto !important;
                                    font-size: 13px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
